/* 
  COLORS:
  faded green: #6B897C
  teal green: #45706A
  yellow: #AE903C
  green: #5C7444
  red: #4E1E23
  dark: #585855
  tan: #EEDDCC
*/

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Manrope", Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: black;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  z-index: 2;
  overflow-x: hidden;
}

.row {
  width: 80%;
  margin: 0 auto;
}

.text-primary {
  color: #4e1e23;
}

.text-secondary {
  color: #45706a;
}

.main__container {
  display: flex;
  flex-direction: column;
}

/*
BROWSER SCROLLBAR
*/

/* CHROME & SAFARI */
html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #ae903c;
  border-radius: 8px;
}

html::-webkit-scrollbar-thumb:hover {
  background: #585855;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:active {
  background: #eeddcc;
  border-radius: 10px;
}

/* FIREFOX */
html::-moz-scrollbar {
  width: 8px;
}

html::-moz-scrollbar-track {
  background-color: transparent;
}

html::-moz-scrollbar-thumb {
  background: #ae903c;
  border-radius: 8px;
}

html::-moz-scrollbar-thumb:hover {
  background: #585855;
  border-radius: 10px;
}

html::-moz-scrollbar-thumb:active {
  background: #eeddcc;
  border-radius: 10px;
}

/* INTERNET EXPLORER */
html::-ms-scrollbar {
  width: 8px;
}

html::-ms-scrollbar-track {
  background-color: transparent;
}

html::-ms-scrollbar-thumb {
  background: #ae903c;
  border-radius: 8px;
}

html::-ms-scrollbar-thumb:hover {
  background: #585855;
  border-radius: 10px;
}

html::-ms-scrollbar-thumb:active {
  background: #eeddcc;
  border-radius: 10px;
}

/* OPERA */
html::-o-scrollbar {
  width: 8px;
}

html::-o-scrollbar-track {
  background-color: transparent;
}

html::-o-scrollbar-thumb {
  background: #ae903c;
  border-radius: 8px;
}

html::-o-scrollbar-thumb:hover {
  background: #585855;
  border-radius: 10px;
}

html::-o-scrollbar-thumb:active {
  background: #eeddcc;
  border-radius: 10px;
}

/* 
LOADER 
*/

.overflow__body {
  overflow: hidden;
}

#preload {
  display: block;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader__fade {
  transition: all 1s ease-in-out;
  opacity: 0;
}

.loader__logo {
  height: 200px;
  width: 200px;
  margin-bottom: 24px;
}

.fade-out__loader {
  opacity: 0;
  transition: all 1.5s ease-in-out;
}

.remove__loader {
  display: none !important;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #eeddcc;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* 
  CURSOR
*/

.cursor {
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  position: fixed;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  z-index: 1001;
}

.main-cursor {
  border: 2px solid #eeddcc;
  box-shadow: 0 0 10px #eeddcc;
}

.link__hover-effect {
  position: relative;
}

.link__hover-effect::after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 0;
  right: 0;
  background-color: #ae903c;
  transition: all 300ms ease;
}

.link__hover-effect:hover::after {
  left: 0;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .cursor {
    display: none;
  }
}

/* 
  NAV
*/

nav {
  height: 300px;
  z-index: 100;
  position: fixed;
  padding-top: 240px;
  right: 0;
  transform: translate(110%, 0);
  transition: all 500ms ease;
  display: flex;
  align-items: center;
}

nav.open {
  position: fixed;
  width: 300px;
  transform: translate(0, 0);
}

.nav__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 0 20px;
  width: 100%;
  height: 80%;
}

.nav__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  list-style: none;
  width: 100%;
}

.nav__list a {
  box-shadow: 1px 1px 3px #4e1e23;
  background-color: #ae903c;
  border: 1px solid #4e1e23;
  border-right: 0;
  border-radius: 8px 0 0 8px;
  width: 100%;
  text-align: center;
  padding: 20px 40px;
  transition: all 500ms;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 32px;
  color: #4e1e23;
}

.nav__list a:hover {
  background-color: #4e1e23;
  color: #45706a;
}

.nav__burger {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 900;
  position: fixed;
  top: 25px;
  right: 25px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.nav__burger-bar {
  width: 32px;
  height: 5px;
  background-color: #ae903c;
  margin: 3px 0;
  border-radius: 999px;
  transition: all 500ms ease;
  position: absolute;
  box-shadow: 0px 0px 4px black;
}

.nav__burger :nth-child(1) {
  top: 10px;
}

.nav__burger :nth-child(2) {
  top: 20px;
}

.nav__burger :nth-child(3) {
  top: 30px;
}

.nav__burger.open :nth-child(1) {
  transform: rotate(45deg);
  top: 20px;
}

.nav__burger.open :nth-child(2) {
  transform: translate(200%);
  opacity: 0;
}

.nav__burger.open :nth-child(3) {
  transform: rotate(-45deg);
  top: 20px;
}

.nav__burger.open :nth-child(1),
.nav__burger.open :nth-child(2),
.nav__burger.open :nth-child(3) {
  position: absolute;
  background-color: #eeddcc;
}

@media screen and (max-width: 800px) {
  nav {
    height: 100vh;
    background-color: #6b897c;
    padding-top: 0;
    display: flex;
    align-items: center;
    transition: all 300ms ease;
  }
  nav.open {
    width: 100vw;
  }
  .nav__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    width: 60%;
  }
  .nav__list a {
    border-radius: 8px;
    background-color: transparent;
  }
  .nav__burger {
    top: 10px;
    right: 10px;
  }
}

/* 
  LANDING
*/

.mail-icon > .MuiSvgIcon-root {
  color: #eeddcc;
  background-color: #ae903c;
  width: 72px;
  height: 72px;
  position: fixed;
  padding: 12px;
  transition: all 200ms ease-in-out;
  border-radius: 50%;
  z-index: 99;
  bottom: 24px;
  right: 24px;
}

.mail-icon > .MuiSvgIcon-root:hover {
  transform: scale(1.075);
  box-shadow: 0px 0px 10px #ae903c;
}

.mail-icon > .MuiSvgIcon-root:active {
  transform: scale(0.99);
}

#landing {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing__header,
.landing__sub-header {
  font-size: 104px;
  margin-bottom: 12px;
  line-height: 1;
  color: #ae903c;
}

.landing__text-header {
  font-size: 32px;
  margin-bottom: 24px;
  color: #6b897c;
}

.landing__sub-header {
  margin-bottom: 8px;
}

.hello-there {
  transition: all 300ms ease;
  color: #6b897c;
  display: inline;
}

.hello-there:hover {
  text-shadow: 0px 0px 10px #ae903c;
  color: #45706a;
}

hr {
  width: 50%;
  border: 4px solid #ae903c;
  margin: 10px 0 30px 0;
}

.landing__icons-container {
  margin-bottom: 24px;
}

.landing__icons-container :nth-child(2),
.landing__icons-container :nth-child(3) {
  margin-left: 20px;
}

.landing__icon > .MuiSvgIcon-root {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 6px;
  transition: all 200ms ease;
  background-color: #6b897c;
  color: black;
}

.landing__icon > .MuiSvgIcon-root:hover {
  box-shadow: 0px 0px 10px #ae903c;
  transform: scale(1.3);
}

.landing__icon > .MuiSvgIcon-root:active {
  transform: scale(0.98);
}

.landing__button {
  display: inline-block;
  padding: 15px 64px;
  background-color: #ae903c;
  text-decoration: none;
  border: 1px solid #ae903c;
  border-radius: 5px;
  transition: all 500ms ease;
  color: black;
}

.landing__button:hover {
  background-color: transparent;
  box-shadow: 0px 0px 10px #ae903c;
  color: #ae903c;
}

.click {
  transition: all 300ms ease;
  cursor: pointer;
}

.click:hover {
  transform: scale(1.1);
}

.click:active {
  transform: scale(0.8);
}

.scroll {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.scroll__icon {
  width: 20px;
  height: 30px;
  border: 2px solid #ae903c;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll__icon:after {
  content: "";
  width: 4px;
  height: 6px;
  background-color: #ae903c;
  border-radius: 2px;
  animation: scroll 1000ms infinite alternate-reverse;
}

@keyframes scroll {
  0% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-3px);
  }
}

@media screen and (max-width: 900px) {
  .landing__header,
  .landing__sub-header {
    font-size: 80px;
  }
  .landing__text-header {
    font-size: 20px;
  }
  .scroll__icon {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .landing__text-container {
    margin: 0 16px;
  }
  .landing__header,
  .landing__sub-header {
    font-size: 42px;
  }
  .landing__text-header {
    font-size: 18px;
  }
  .landing__button {
    padding: 12px 48px;
  }
  .mail-icon > .MuiSvgIcon-root {
    height: 56px;
    width: 56px;
    bottom: 10px;
    right: 10px;
  }
}

.canvasbg {
  position: absolute;
  top: 0;
  z-index: 1;
  max-width: 100vw;
  width: 100%;
  height: 100vh;
}

/* 
ABOUT 
*/

#waves {
  position: absolute;
  z-index: -10;
  width: 100%;
  margin-top: -1px;
}

.about {
  z-index: 1;
  position: relative;
  background-color: #eeddcc;
  overflow: hidden;
  display: flex;
}

.about__wrapper {
  z-index: 10;
  color: #4e1e23;
  margin: 500px auto 0px;
  padding: 40px;
  max-width: 1000px;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);
}

.about__left {
  font-size: 42px;
  font-weight: 400;
}

.about__img--wrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 3px 12px #4e1e23;
  margin: 0 auto 32px auto;
}

.about__img {
  width: 100%;
  transform: scale(1.2);
}

.about__title {
  font-size: 26px;
  margin: 140px auto 32px;
}

.about__right {
  line-height: 28px;
  font-size: 18px;
}

@media screen and (max-width: 900px) {
  .about__title {
    margin: 0 auto 32px auto;
  }
  .about__wrapper {
    grid-template-columns: 1fr;
    margin: 300px auto 32px;
    padding: 0 120px;
  }
  .about__left {
    font-size: 24px;
  }
  .about__right {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  #about {
    width: 100%;
  }
  .about__wrapper {
    margin: 200px auto 32px;
    padding: 0 40px;
  }
  .about__left {
    padding-right: 16px;
  }
  .about__right {
    padding-right: 20px;
  }
}

/* 
SKILLS
*/

#skills {
  background-color: #eeddcc;
  overflow: hidden;
}

.skills__header {
  text-align: center;
  color: #4e1e23;
  font-size: 52px;
  padding-top: 72px;
}

.skills__button--wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  max-width: 1200px;
  margin: 50px auto;
}

.skill__buttons {
  position: relative;
  font-size: 100px;
  padding: 10px;
  border-radius: 10%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 0px 1px #ae903c;
}

.skills__button {
  transform: scale(0);
  transition: all 500ms ease 0s;
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  bottom: -10px;
}

.skill__buttons:hover .skills__button {
  transform: scale(1);
  transition: all 300ms ease 0s;
}

.skill__buttons svg {
  transform: scale(1);
  transition: all 300ms ease 0s;
}

.skill__buttons:hover svg {
  transform: scale(0.9);
  filter: brightness(0.8);
  transition: all 300ms ease 0s;
}

@media (max-width: 900px) {
  #skills {
    flex-direction: column;
  }
  .skills__header {
    padding-top: 0px;
  }
  .skills__button--wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .skills__button {
    transform: scale(1);
  }
  .skill__buttons svg {
    transform: scale(0.9);
    transition: all 300ms ease 0s;
  }
}

@media (max-width: 500px) {
  .skills__button--wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  #skills {
    padding-right: 20px;
  }
}

/* 
  PROJECTS
*/

#projects-waves {
  width: 100%;
  position: absolute;
  z-index: -1;
  margin-top: -1px;
}

.projects {
  background-color: #585855;
  overflow: hidden;
}

#projects {
  margin: 400px 0 100px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.projects__header {
  text-align: center;
  font-size: 52px;
  color: #ae903c;
}

.projects__text-container {
  width: 100%;
  margin-bottom: 64px;
}

.projects__text {
  max-width: 550px;
}

.projects__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.project {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
}

.project__description {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  transition: all 300ms ease-in-out;
}

.project__title {
  color: #ae903c;
  font-size: 24px;
  margin-bottom: 10px;
}

.project__info {
  color: #eeddcc;
  margin-bottom: 16px;
  padding-bottom: 2px;
  cursor: pointer;
  font-weight: bold;
}

.project__links .MuiSvgIcon-root {
  color: #eeddcc;
  font-size: 24px;
}

.project__link:hover .MuiSvgIcon-root {
  transform: translateY(-1px);
}

.project__link:active .MuiSvgIcon-root {
  transform: translateY(1px);
}

.project__link:nth-child(1) .MuiSvgIcon-root {
  margin-right: 16px;
}

.project:hover .project__description {
  transform: translateY(0);
}

.project__img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: all 300ms ease-in-out;
}

.projects__btn--wrapper {
  margin: 40px auto;
}

.projects__btn {
  cursor: pointer;
  padding: 15px 64px;
  background-color: #ae903c;
  text-decoration: none;
  border: 1px solid #ae903c;
  border-radius: 5px;
  transition: all 500ms;
  color: #4e1e23;
}

.projects__btn:hover {
  background-color: transparent;
  box-shadow: 0px 0px 10px #ae903c;
  color: #ae903c;
}

@media (max-width: 1200px) {
  .projects__text {
    text-align: center;
    margin: 0 auto;
  }
  #projects {
    margin: 200px 20px 0 0;
  }
}

@media (max-width: 600px) {
  #projects {
    flex-direction: column;
    margin: 100px 20px 0 0;
  }
  .projects__text {
    width: 340px;
    text-align: center;
  }
  .projects__wrapper {
    grid-template-columns: 1fr;
  }
}

.modal__open {
  overflow: hidden;
}

.modal__container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #45706a;
  z-index: 1000;
  -webkit-animation: fadeIn 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
}

.modal__close {
  position: fixed;
  top: 20px;
  right: 20px;
  color: black;
  background-color: #ae903c;
  font-size: 30px;
  display: flex;
  padding: 10px;
  cursor: pointer;
  z-index: 2000;
  border-radius: 50%;
}

.modal__close svg {
  transition: all 300ms ease-in-out;
}

.modal__close:hover svg {
  transform: rotate(90deg);
  opacity: 0.5;
}

.modal__top {
  max-width: 1000px;
  height: 100%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #585855;
  position: relative;
  border-radius: 4px;
}

.modal__top--image {
  display: flex;
  justify-content: center;
}

.modal__top--image img {
  border-radius: 4px;
  width: 95%;
}

.modal__scroll-down {
  position: absolute;
  bottom: 50px;
  color: #ae903c;
  font-size: 20px;
  padding: 10px;
}

.modal__project--background {
  background-color: #eeddcc;
}

.modal__top--background {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal__title {
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 10px;
}

.modal__type {
  margin-bottom: 20px;
}

.modal__description {
  margin-bottom: 25px;
}

.modal__view-online--button {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.modal__view-online--button .MuiSvgIcon-root {
  margin-left: 8px;
}

.modal__view--button-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 26px;
}

.modal__project--wrapper {
  color: black;
  text-align: center;
  padding: 50px 20px;
  margin: 20px auto 75px;
  max-width: 800px;
  font-weight: 300;
}

@media screen and (max-width: 600px) {
  .modal__top {
    background-color: #45706a;
  }
  .modal__project--wrapper {
    margin-bottom: 20px;
  }
  .modal__view--button-wrapper {
    font-size: 18px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 
  CONTACT
*/

#contact {
  background-color: #585855;
  overflow: hidden;
}

.contact__sub-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  max-height: 800px;
  padding: 88px 0;
}

.contact__form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 620px;
  padding: 0 16px;
}

.contact__form input,
.contact__form textarea {
  background-color: #eeddcc;
  color: #4e1e23;
  opacity: 0.7;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
  padding: 16px 8px;
  font-size: 16px;
  transition: all 500ms;
}

.contact__form input:focus,
.contact__form textarea:focus {
  outline: none;
  opacity: 1;
}

.contact__form input:hover,
.contact__form textarea:hover {
  opacity: 1;
}

.contact__header-text {
  color: #ae903c;
  -webkit-animation: fadeInUp 250ms linear;
  animation: fadeInUp 250ms linear;
  font-size: 40px;
}

.contact__header-text--success {
  color: #45706a;
  text-shadow: #45706a 0 0 10px;
  -webkit-animation: fadeInUp 250ms linear;
  animation: fadeInUp 250ms linear;
}

.contact__header-text--error {
  color: #4e1e23;
  text-shadow: #4e1e23 0 0 10px;
}

.g-recaptcha {
  margin-top: 20px;
}

.contact__form-btn {
  background-color: #ae903c;
  color: #eeddcc;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
  padding: 16px 8px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ae903c;
  transition: all 500ms;
}

.contact__form-btn:hover {
  background-color: transparent;
  box-shadow: 0px 0px 10px #ae903c;
}

.contact__form-btn:active {
  transform: scale(0.98);
}

.contact__loading-spinner {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.contact__canvas {
  width: 45%;
  height: 60vw;
  margin-left: 40px;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@media screen and (max-width: 900px) {
  .contact__sub-container {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-height: none;
    padding-bottom: 0;
  }
  .row {
    width: 90% !important;
  }
  .contact__form {
    width: 100%;
    margin: 0 auto;
  }
  .contact__header-text {
    font-size: 32px;
  }
  .contact__canvas {
    width: 100%;
    height: 650px;
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .contact__form {
    padding: 0 40px 40px 0;
  }
  .main__container {
    flex-direction: column;
  }
  .hide-mobile {
    display: none;
  }
  .container {
    margin-left: 0;
    width: 100%;
    overflow-x: hidden;
  }
  * {
    cursor: default;
  }
  h1 {
    font-size: 40px;
  }
}

/* 
  FOOTER
*/

#footer {
  position: relative;
  background-color: #242424;
  display: flex;
}

.footer-waves {
  position: absolute;
  width: 100%;
  margin-top: -1px;
}

.footer__row {
  margin: 200px auto 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__logo--img {
  width: 70px;
}

.footer__anchor {
  position: relative;
}

.footer__anchor:hover .footer__logo--popper {
  opacity: 1;
}

.footer__logo--popper {
  color: #ae903c;
  position: absolute;
  display: flex;
  align-content: center;
  left: 10px;
  top: -20px;
  font-weight: 700;
  opacity: 0;
  transition: all 300ms ease;
}

.footer__logo--popper > svg {
  margin: 4px;
}

.footer__social--list {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  max-width: 500px;
  margin: 28px 0;
}

.footer__social--link,
.footer__copyright,
.footer__email {
  color: #eeddcc;
}

.footer__social--link {
  display: flex;
  align-items: center;
}

.footer__email {
  margin-bottom: 28px;
}

.footer__social--link > svg {
  margin-right: 4px;
}

.footer__icon--container {
  display: flex;
  margin-bottom: 28px;
}

.footer__icon {
  background-color: transparent;
  color: #eeddcc;
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 12px 18px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #eeddcc;
  transition: all 300ms;
}

.footer__icon:hover {
  background-color: #eeddcc;
  color: #242424;
  box-shadow: 0px 0px 10px #eeddcc;
}

.footer__icon:active {
  transform: scale(0.98);
}

.footer__modal {
  position: absolute;
  z-index: 100;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -75%);
  border-radius: 20px;
  background-color: #242424;
  transition: all 300ms ease;
  -webkit-animation: fadeIn 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
}

.footer__modal--close {
  position: absolute;
  top: 6px;
  right: 6px;
  color: #242424;
  font-size: 24px;
  z-index: 100;
  background-color: #ae903c;
  padding: 4px;
  cursor: pointer;
  display: flex;
  border-radius: 50%;
}

.footer__modal--close svg {
  transition: all 300ms ease-in-out;
}

.footer__modal--close:hover svg {
  transform: rotate(90deg);
  opacity: 0.5;
}

.footer__modal--img--wrapper {
  width: 240px;
}

.footer__modal--img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.footer__modal--title {
  z-index: 101;
  color: #242424;
  position: absolute;
  font-size: 24px;
  bottom: 8px;
  right: 40px;
}

@media screen and (max-width: 900px) {
  .footer__row {
    margin: 112px auto 56px;
  }
}

::-moz-placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
  opacity: 1;
}

::placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
}

::-ms-input-placeholder {
  color: black;
}

/* 
  RUBBERBAND
*/

span.rubberBand {
  display: inline-block;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  font-weight: bold;
}

span.rubberBand.animated {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
